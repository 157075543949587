<template>
  <section class="m-wallet-package m-scroll">

    <!-- 头部导航 -->
    <m-header title="卡包"></m-header>


    <div class="card-list" v-show="list.length != 0">
      <div class="title">
        <img src="@/assets/wallet/icon_yinhangka@2x.png" alt="">
        <span>我的银行卡</span>
      </div>

      <div class="card-item" v-for="(it, index) in list" :key="index">
        <p>Bank Card</p>
        <div v-show="!it.show" class="card-number">
          <span>*****</span>
          <span>***</span>
          <span>*******</span>
          <img  @click="onToggle(it)" src="@/assets/wallet/icon_offbiyan@2x.png" alt="">
        </div>
        <div v-show="it.show" class="card-number">
          <span>{{it.transit_number}}</span>
          <span>{{it.institiution_number}}</span>
          <span>{{it.account_number}}</span>
          <img @click="onToggle(it)" src="@/assets/wallet/eye_on.png" alt="">
        </div>
        <img src="@/assets/wallet/icon_delet@2x.png" alt="Delete" @click="handleDel(it.id, index)"> 
      </div>
     
    </div>
  
    <div class="btn-box">
      <router-link to="/zh/wallet/bankcard" class="btn-item">
        <img src="@/assets/wallet/add.png" alt="">
        <span>添加银行卡</span>
      </router-link>
    </div>







    

  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'

import { card_list, card_del } from '@/api/zh/mine.js'

export default {
  name:'CardList',
  components:{ MHeader },
  data(){
    return {
      list:[],
    }
  },

  

  methods:{

    onToggle(it){
      it.show = !it.show
    },

    getData(){
      this.$store.state.load = true
      card_list().then(res => {
        if(res) {
          this.list = res.data
          this.list.forEach(e => {
            this.$set(e, 'show', false)
          })
        }
      }).finally( () => {
        this.$store.state.load = false
      })
    },

    handleDel(id, index) {
      card_del(id).then( res => {
        if(res) {
          this.$notify({ type: 'success', message: res.msg})
          this.list.splice(index, 1)
        }
      })
    }

    

  },

  created() {
    this.getData()
  }

}
</script>

<style lang="less" scoped>
.m-wallet-package {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  .card-list {
    margin-top: 10px;
    padding: 0 18px 20px;
    background-color: #fff;
    .title {
      padding-left: 2px;
      height: 47px;
      display: flex;
      align-items: center;
      >img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
      >span {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        line-height: 22px;
      }
    }
    .card-item {
      height:109px;
      background:linear-gradient(135deg,rgba(45,119,224,1) 0%,rgba(36,95,179,1) 100%);
      border-radius:5px;
      position: relative;
      padding: 0 34px 0 28px;
      margin-bottom: 16px;
      >p {
        font-size:16px;
        font-weight:bold;
        color:rgba(255,255,255,1);
        line-height:25px;
        padding: 14px 0 18px;
      }
      >div {
        color: #fff;
        height: 28px;
        display: flex;
        align-items: center;
        >span {
          font-size:20px;
          font-weight:600;
          color:rgba(255,255,255,1);
          line-height:28px;
          margin-right: 10px;
        }
        >img {
          width: 24px;
          height: 24px;
          margin-left: 6px;
        }
      }
      &>img {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 42px;
        right: 8px;
      }
    }
    .card-number {
      transition: all 0.5s;
    }
  }
  
  .btn-box {
    background: #fff;
    margin-top: 30px;
    padding: 30px 46px ;
    .btn-item {
      width: 100%;
      height: 44px;
      border: 1px solid #3284FA;
      border-radius: 32px;
      font-size: 12px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      >img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
      >span {
        font-size: 16px;
        font-weight: 400;
        color: #3284FA;
      }
    }
  }
}
</style>